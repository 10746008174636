<script setup lang="ts">
import { RoomType } from '@/Pages/the-home/enums/Search'
import { HeaderSearchResultEmitType } from '@/Components/the-header-search-result/enums/HeaderSearchResultEmitType'
import { computed, watch } from 'vue'
import { showCriteriaSearchModal } from '@/Composables/use-scroll-&-open-search'

type Props = {
    location: string
    dates?: string
    roomType?: string
    guests?: string | number
    rooms?: string | number
    isExpanded?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    dates: 'Choose date',
    roomType: RoomType.DORM,
    guests: 'Choose guests',
    rooms: 'Choose rooms',
    isExpanded: false
})

const emit = defineEmits<{
    expand: [value: HeaderSearchResultEmitType]
}>()

const roomTypesTitle = computed(() => {
    return props.roomType === RoomType.DORM
        ? 'Dorm Bed'
        : 'Private Room'
})

watch(showCriteriaSearchModal, () => {
    emit('expand', showCriteriaSearchModal.value)
}, { deep: true })

</script>

<template>
    <div class="cl-text font-weight-600">
        <button v-if="isExpanded" class="cursor-pointer btn-clear p-0 d-none d-lg-block">
            Where are you going?
        </button>

        <div v-else id="header-search-result__info" class="cursor-pointer">
            <span class="delimiter" @click="emit('expand', HeaderSearchResultEmitType.LOCATION)">{{ location }}</span>
            <span class="delimiter" @click="emit('expand', HeaderSearchResultEmitType.DATES)">{{ dates }}</span>
            <span
                class="delimiter d-none d-lg-inline"
                @click="emit('expand', HeaderSearchResultEmitType.ROOM_TYPE)"
            >{{ roomTypesTitle }}</span>
            <span class="delimiter" @click="emit('expand', HeaderSearchResultEmitType.GUESTS)">{{ guests }}</span>
            <span
                v-if="roomType === RoomType.PRIVATE"
                class="delimiter d-none d-lg-inline"
                @click="emit('expand', HeaderSearchResultEmitType.ROOMS)"
            >{{ rooms }}</span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.delimiter + .delimiter::before {
    display: inline-block;
    padding: 0 0.5rem;
    color: #787D8D;
    content: "·";
}
</style>