<script setup lang="ts">
import { onMounted, onBeforeUnmount, computed } from 'vue'
import { Link, usePage } from '@inertiajs/vue3'
import TheIcon from './TheIcon.vue'
import { useWindowSize } from '@vueuse/core'
import { bottomBar, bottomBarHide, bottomBarRef, bottomBarShow } from '@/Composables/bottomBarState'
import { isUserLogged } from '@/Composables/authorization'
import { comparisonCount } from '@/Composables/use-comparison'

const { width } = useWindowSize()
const isMobile = computed(() => width.value < 992)

let lastScrollY: number

const handleScroll = (): void => {
    if (isMobile.value) {
        const currentScrollY = window.scrollY
        if (currentScrollY > lastScrollY && currentScrollY > 160) {
            bottomBarHide()
        } else {
            bottomBarShow()
        }
        lastScrollY = currentScrollY
    }
}

const page = usePage()

onMounted(() => {
    lastScrollY = window.scrollY
    window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll)
})

</script>

<template>
    <div
        id="userBottomMenu"
        ref="bottomBarRef"
        class="d-lg-none toogleOnScroll bottom-bar"
        :class=" { hidden: !bottomBar}"
    >
        <Link class="bottom-menu-item col-3  bottom-bar-home-link" :href="route('home')" title="Home">
            <TheIcon
                icon-id="logo-min-bottom-bar"
                width="15"
                height="24"
                :class="{ 'main-page': page['url'] === '/', 'other-pages': page['url'] !== '/'}"
                class="flex-shrink-0 bottom-bar-home-icon"
            />

            <span class="pre-title bottom-bar-home-text" :class="{ 'cl-primary ': page['url'] === '/'}">Home</span>
        </Link>

        <Link
            id="userBottomMenuPluzClose"
            class="bottom-menu-item col-3 bottom-bar-blog-link"
            :href="route('articles', 'best-hostel-tips-backpacking')"
            title="Pluz"
        >
            <svg
                v-if="!isUserLogged"
                width="25"
                height="24"
                viewBox="0 0 64 64"
                class="lock-close-icon"
                :class="{ 'lock-close-icon--blog': page['url'].includes('/articles') }"
                xmlns="http://www.w3.org/2000/svg"
            >

                <path
                    stroke-miterlimit="10"
                    d="M19 30.3v-15a13 13 0 0 1 13-13 13 13 0 0 1 13 13v15"
                    data-name="layer2"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    class="lock-close-icon-top-wrap"
                    :class="{ 'lock-close-icon-top-wrap--blog': page['url'].includes('/articles') }"
                />

                <path
                    stroke-miterlimit="10"
                    d="M23 30.3v-15a9 9 0 0 1 18 0v15"
                    data-name="layer2"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    class="lock-close-icon-top-inner"
                    :class="{ 'lock-close-icon-top-inner--blog': page['url'].includes('/articles') }"
                />

                <path
                    d="M10 29.8h44v32H10z"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    data-name="layer1"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                />

                <path
                    stroke-width="2"
                    stroke-miterlimit="10"
                    class="lock-close-icon-keyhole"
                    :class="{ 'lock-close-icon-keyhole--blog': page['url'].includes('/articles') }"
                    d="M34 48.7a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2 2 2 0 0 1 2 2z"
                    data-name="layer1"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                />
            </svg>

            <svg
                v-else
                width="25"
                height="24"
                viewBox="0 0 64 64"
                class="lock-open-icon"
                :class="{ 'lock-open-icon--blog': page['url'].includes('/articles') }"
                xmlns="http://www.w3.org/2000/svg"
            >

                <path
                    data-name="layer2"
                    d="M19 30V15A13 13 0 0 1 32 2a13 13 0 0 1 13 13v2a2 2 0 0 1-4 0v-2a9 9 0 1 0-18 0v15"
                    stroke-miterlimit="10"
                    stroke-width="2"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                />

                <path
                    data-name="layer1"
                    stroke-miterlimit="10"
                    stroke-width="2"
                    d="M10 30h44v32H10z"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                />

                <path
                    data-name="layer1"
                    d="M34 49a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2 2 2 0 0 1 2 2z"
                    stroke-miterlimit="10"
                    stroke-width="2"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    class="lock-open-icon-keyhole"
                    :class="{ 'lock-open-icon-keyhole--blog': page['url'].includes('/articles') }"
                />
            </svg>

            <span class="pre-title  bottom-bar-blog-text" :class="{ 'cl-primary ': page['url'].includes('/articles') }">Pluz</span>
        </Link>

        <Link
            class="comparison-sticky-mobile bottom-menu-item col-3 bottom-bar-comparison-link"
            :href="route('comparison')"
            title="Comparison"
        >
            <div class="position-relative">
                <TheIcon
                    icon-id="comparison-tool-bottom-bar"
                    width="24"
                    height="28"
                    :class="{'fill-primary' : page['url'].includes('/compare')}"
                    class="bottom-bar-comparison-icon"
                />

                <div class="comparison-count ml-1 bottom-bar-count">
                    {{ comparisonCount }}
                </div>
            </div>
            <span
                class="pre-title bottom-bar-comparison-text"
                :class="{'cl-primary': page['url'].includes('/compare')}"
            >Comparizon</span>
        </Link>

        <a
            v-if="isUserLogged"
            id="userBottomMenuLogin"
            class="bottom-menu-item col-3 bottom-bar-login-link"
            :href="route('user:menu')"
            title="Dashboard"
        >

            <TheIcon
                icon-id="user-icon-bottom-bar"
                width="25"
                height="24"
                :class="{'fill-primary ': page['url'] === '/user'}"
                class="user-icon"
            />

            <span
                class="pre-title bottom-bar-login-text"
                :class="{'cl-primary': page['url'] === '/user'}"
            >{{ isUserLogged ? 'Dashboard' : 'Login' }}</span>
        </a>
        <Link
            v-else
            id="userBottomMenuLogin"
            class="bottom-menu-item col-3 bottom-bar-login-link"
            :href="route('loginShow')"
            title="Login"
        >
            <TheIcon
                class="user-icon"
                icon-id="user-icon-bottom-bar"
                width="25"
                height="24"
                :class="{'fill-primary ': page['url'] === '/login'}"
            />

            <span
                class="pre-title bottom-bar-login-text"
                :class="{ 'cl-primary ': page['url'] === '/login'}"
            >{{ isUserLogged ? 'Dashboard' : 'Login' }}</span>
        </Link>
    </div>
</template>

<style scoped lang="scss">
.bottom-bar {
    &-home {
        &-link {
            &:hover {
                .bottom-bar-home-text {
                    color: var(--primary-brand);
                }

                .bottom-bar-home-icon {
                    fill: var(--primary-brand);
                }
            }
        }

        &-text {
            transition: color .3s;
            color: var(--secondary-text);
        }

        &-icon {
            transition: fill .3s, stroke .3s;
        }
    }

    &-blog {
        &-link {
            &:hover {
                //lock-open
                .lock-open-icon {
                    fill: var(--primary-brand);
                    stroke: var(--primary-brand);
                }

                .lock-open-icon-keyhole {
                    fill: var(--blue-10);
                    stroke: var(--blue-10);
                }

                //lock-close
                .lock-close-icon {
                    fill: var(--primary-brand);
                    stroke: var(--primary-brand);
                }

                .lock-close-icon-keyhole {
                    fill: var(--blue-10);
                    stroke: var(--blue-10);
                }

                .lock-close-icon-top-wrap {
                    stroke-width: 2;

                    stroke: var(--primary-brand);
                    fill: transparent;
                }

                .lock-close-icon-top-inner {
                    stroke-width: 6;

                    stroke: var(--primary-brand);
                    fill: transparent;
                }

                .bottom-bar-blog-text {
                    color: var(--primary-brand);
                }
            }
        }

        &-text {
            transition: color .3s;
            color: var(--secondary-text);
        }
    }

    &-login {
        &-link {
            &:hover {
                .user-icon {
                    fill: var(--primary-brand);
                }

                .bottom-bar-login-text {
                    color: var(--primary-brand);
                }
            }
        }

        &-text {
            transition: color .3s;
            color: var(--secondary-text);
        }
    }

    &-comparison {
        &-link {

            .fill-primary {
                fill: var(--primary-brand);
            }

            &:hover {
                svg {
                    fill: var(--primary-brand);
                }

                .bottom-bar-comparison-icon {
                    fill: var(--primary-brand);
                }
            }
        }

        &-text {
            transition: color .3s;
            color: var(--secondary-text);
        }

        &-icon {
            transition: fill .3s,;
            fill: var(--secondary-text);
        }
    }

    &-count {
        opacity: 1;
        transition: opacity .3s, color .3s;

        &.hidden-count {
            opacity: 0;
        }

        &.bottom-bar-show {
            transform: translateY(-100%);
        }
    }
}

.bottom-bar.hidden {
    transform: translateY(100%);
}

#userBottomMenu {
    z-index: 1053;
}

.main-page {
    fill: var(--primary-brand);
}

.other-pages {
    fill: var(--secondary-text);
}

.user-icon {
    transition: fill .3s;

    fill: var(--secondary-text);
}

.fill-primary {
    transition: fill .3s, stroke .3s;

    fill: var(--primary-brand);
}


// lock-open
.lock-open-icon {
    transition: fill .3s, stroke .3s;

    stroke: var(--main-text);
    fill: transparent;

    &--blog {
        fill: var(--primary-brand);
        stroke: var(--primary-brand);
    }
}

.lock-open-icon-keyhole {
    transition: fill .3s, stroke .3s;

    stroke: var(--main-text);
    fill: transparent;

    &--blog {
        fill: var(--blue-10);
        stroke: var(--blue-10);
    }
}

//lock-close
.lock-close-icon {
    transition: fill .3s, stroke .3s;

    fill: transparent;
    stroke: var(--main-text);

    &--blog {
        fill: var(--primary-brand);
        stroke: var(--primary-brand);
    }
}

.lock-close-icon-keyhole {
    transition: fill .3s, stroke .3s;

    fill: transparent;
    stroke: var(--main-text);

    &--blog {
        fill: var(--blue-10);
        stroke: var(--blue-10);
    }
}

.lock-close-icon-top-wrap {
    transition: fill .3s, stroke .3s;

    stroke-width: 2;

    stroke: var(--main-text);
    fill: transparent;

    &--blog {
        stroke-width: 2;

        stroke: var(--primary-brand);
        fill: transparent;
    }
}

.lock-close-icon-top-inner {
    transition: fill .3s, stroke .3s, stroke-width .3s;

    stroke-width: 2;

    fill: transparent;
    stroke: var(--main-text);

    &--blog {
        stroke-width: 6;

        stroke: var(--primary-brand);
        fill: transparent;
    }
}
</style>