<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, computed, watch } from 'vue'
import { Link, usePage } from '@inertiajs/vue3'
import CurrencyExchange from '../../Components/CurrencyExchange.vue'
import AppModal from '../the-modal/AppModal.vue'
import {
    setCurrentCurrency,
    currentCurrency,
    setCurrencyForServer,
    handleShowCurrencyModal,
    showCurrencyModal
} from '@/Composables/currencyExchange'
import TheIcon from '../TheIcon.vue'
import {
    isUserLogged,
    setUserInfo,
    toggleIsUserLogged,
    userInfo
} from '@/Composables/authorization'

const page = usePage()

const pageUserAvatar = computed(() => userInfo.value.avatar as string)


const isDropdownOpen = ref(false)

const dropdown = ref<HTMLElement | null>(null)

onMounted(() => {
    document.addEventListener('click', handleClickOutside)

    if (page.props.user) {
        setUserInfo(page.props.user)
    }

    const storedCurrency = localStorage.getItem('currentCurrency')
    const currencyForServer = localStorage.getItem('currencyForServer')

    if (storedCurrency && currencyForServer) {
        setCurrentCurrency(storedCurrency)
        setCurrencyForServer(currencyForServer)
    } else {
        setCurrentCurrency('US Dollars | $')
        setCurrencyForServer('USD')
    }
})

onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside)
})

const openModal = (): void => {
    isDropdownOpen.value = false

    handleShowCurrencyModal()
}

const toggleDropdown = (): void => {
    isDropdownOpen.value = !isDropdownOpen.value
}

const modalClose = (): void => {
    handleShowCurrencyModal()
}

const handleClickOutside = (event: MouseEvent): void => {
    if (dropdown.value && !dropdown.value.contains(event.target as Node)) {
        isDropdownOpen.value = false
    }
}

const logout = (): void => {
    toggleIsUserLogged(false)
}

</script>

<template>
    <li ref="dropdown" class="nav-item dropdown ml-lg-3  login-dropdown">
        <div
            v-if="!isUserLogged"
            class="btn-clear ml-1 login-dropdown-burger btn-circle bg-light"
            @click="toggleDropdown"
        >
            <TheIcon
                icon-id="menu"
                width="24"
                height="24"
            />
        </div>
        <div
            v-else
            class="login-dropdown-avatar"
            :class="{
                'isRegisteredNoAvatar': !pageUserAvatar,
                'isRegisteredWithAvatar': pageUserAvatar
            }"
            @click="toggleDropdown"
        >
            <img v-if="pageUserAvatar" :src="pageUserAvatar" alt="avatar">
            <TheIcon
                v-else
                icon-id="user-icon"
                width="25"
                height="24"
            />
        </div>

        <transition name="slide-fade">
            <div v-if="isDropdownOpen" class="login-dropdown-wrap dropdown-menu-right">
                <div v-if="!isUserLogged">
                    <Link :href="route('signupShow')" class="dropdown-item">
                        Sign up
                    </Link>

                    <Link :href="route('loginShow')" class="dropdown-item">
                        Login
                    </Link>

                    <div class="dropdown-divider" />

                    <Link
                        :href="route('articles', 'best-hostel-tips-backpacking')"
                        class="dropdown-item d-flex align-items-center"
                        title="Exclusive Articles"
                    >
                        Exclusive Articles
                        <span class="badge badge-primary ml-1">Pluz</span>
                    </Link>

                    <Link :href="route('howCompareHostelPrices')" class="dropdown-item" title="How Hostelz works">
                        How Hostelz works
                    </Link>

                    <div class="dropdown-divider" />

                    <div class="dropdown-item cursor-pointer" @click="openModal">
                        {{ currentCurrency }}
                    </div>
                </div>
                <div v-else>
                    <a :href="route('user:menu')" class="dropdown-item">
                        Dashboard
                    </a>

                    <a :href="route('wishlist:index')" class="dropdown-item">
                        Your Wishlists
                    </a>

                    <Link
                        :href="route('articles', 'best-hostel-tips-backpacking')"
                        class="dropdown-item d-flex align-items-center"
                        title="Exclusive Articles"
                    >
                        Exclusive Articles
                        <span class="badge badge-primary ml-1">Pluz</span>
                    </Link>

                    <div class="dropdown-divider" />

                    <div class="dropdown-item cursor-pointer" @click="openModal">
                        {{ currentCurrency }}
                    </div>

                    <div class="dropdown-divider" />

                    <Link :href="route('logout')" class="dropdown-item" @click="logout">
                        Logout
                    </Link>
                </div>
            </div>
        </transition>
    </li>

    <AppModal
        :show="showCurrencyModal"
        class="loginDropdownModal"
        @close="modalClose"
    >
        <CurrencyExchange @close-modal="modalClose" />
        <template #close-icon>
            <span class="close-modal-btn">&times;</span>
        </template>
    </AppModal>
</template>


<style scoped lang="scss">

.slide-fade-enter-active, .slide-fade-leave-active {
    transition: transform 0.2s, opacity 0.5s;
}

.slide-fade-enter-from {
    transform: translateY(30px);
    opacity: 0;
}

.slide-fade-enter-to {
    transform: translateY(0);
    opacity: 1;
}

.slide-fade-leave-from {
    transform: translateY(0);
    opacity: 1;
}

.slide-fade-leave-to {
    transform: translateY(30px);
    opacity: 0;
}


.login-dropdown {
    &-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;

        background-color: white;
        border-radius: 50%;
        border: 2px solid grey;

        cursor: pointer;

        img {
            object-fit: cover;
        }
    }

    &-wrap {
        background-clip: padding-box;
        background-color: #fff;
        border: 0 solid rgba(0, 0, 0, .15);
        border-radius: 6px;
        color: #202429;
        font-size: 1rem;
        list-style: none;
        margin: .125rem 0 0;
        min-width: 10rem;
        padding: .5rem 0;
        position: absolute;
        text-align: left;
        top: 100%;
        z-index: 1;
        box-shadow: 0 0 1.5rem rgba(0, 0, 0, .175);

        border: none;
        color: #333;
        font-size: .9rem;
        margin-top: 21px;
        min-width: 200px;
        outline: none;

        &:after {
            border-bottom: 7px solid #fff;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            content: "";
            display: block;
            height: 0;
            left: 30px;
            position: absolute;
            top: -5px;
            width: 0;
            left: auto;
            position: absolute;
            right: 18px;
            top: -5px;
        }
    }

    &-burger {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color .3s;

        &:hover {
            background-color: #dae0e5 !important;
        }

        &:deep(.the-icon-component) {
            display: flex;
        }
    }
}

.close-modal-btn {
    color: #000;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    opacity: .5;
    text-shadow: 0 1px 0 #fff;
}

.isRegisteredNoAvatar {
    border-color: #343a40;
    background-color: #343a40;
    width: 48px;
    height: 48px;
    box-shadow: 0 0 1rem rgba(0, 0, 0, .15);

    .the-icon-component {
        filter: invert(1) brightness(2);
    }
}

.isRegisteredWithAvatar {
    border-color: transparent;
    background-color: transparent;
    width: 48px;
    height: 48px;
    overflow: hidden;
    box-shadow: 0 0 1rem rgba(0, 0, 0, .15);

    img {
        max-width: 100%;
        height: 100%;
    }
}
</style>

<style lang="scss">
.loginDropdownModal {
    .app-modal {
        z-index: 1800;
    }
}
</style>
