import { ref } from 'vue'

export const cookies = ref(false)

export const cookiesHide = ():void => {
    cookies.value =  false
}

export const cookiesShow = ():void => {
    cookies.value = true
}

export const cookiesAnimationMoveToTop = ref(false)

export const toggleCookiesAnimationMoveToTop = (value: boolean):void => {
    cookiesAnimationMoveToTop.value = value
}