<script setup lang="ts">

import { onMounted, onUnmounted, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { bottomBar } from '@/Composables/bottomBarState'

type Props = {
    distanceFromTop?: number
}

const props = withDefaults(defineProps<Props>(), {
    distanceFromTop: 300
})

const showScrollBtn = ref(false)

onMounted(() => {
    window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll)
})

function handleScroll (): void {
    showScrollBtn.value = window.scrollY > props.distanceFromTop
}

function scrollTop (): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

</script>

<template>
    <Transition name="fade">
        <button
            v-if="showScrollBtn"
            class="scroll-top-btn"
            :class="{'scroll-top-btn-raise': bottomBar}"
            @click="scrollTop"
        >
            <FontAwesomeIcon :icon="faArrowUp" />
        </button>
    </Transition>
</template>

<style lang="scss" scoped>
.scroll-top-btn {
    position: fixed;
    bottom: 20px;
    right: 10px;
    text-decoration: none;
    font-size: 22px;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 1px solid #ff635c;
    color: white;
    background-color: #ff635c;
    padding: 0.25rem;
    z-index: 1053;

    transition: bottom 0.3s linear;

    @media (min-width: 992px) {
        right: 30px;
    }

    &-default {
        bottom: 20px;
    }

    &-raise {
        bottom: 60px;

        @media (min-width: 992px) {
            bottom: 20px;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>