<script setup lang="ts">

import { Link } from '@inertiajs/vue3'
import { computed } from 'vue'
import dayjs from 'dayjs'
import TheIcon from '@/Components/TheIcon.vue'
import { usePage } from '@inertiajs/vue3'
import { currentCurrency, handleShowCurrencyModal } from '@/Composables/currencyExchange'
import BannerSignup from '@/Components/banner-signup-form/BannerSignup.vue'

defineProps<{
    isHideSignup?: boolean
}>()

const page = usePage()

const footer = computed(() => page.props.footer)

const currentYear = computed(() => {
    return dayjs().year()
})

const showCurrencyAndScrollTop = (): void => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    window.addEventListener('scroll', checkIfScrolledToTop)
}

const checkIfScrolledToTop = (): void => {
    if (window.scrollY === 0) {
        handleShowCurrencyModal()
        window.removeEventListener('scroll', checkIfScrolledToTop)
    }
}

</script>

<template>
    <footer>
        <BannerSignup
            v-if="!isHideSignup"
            id="signup"
            is-big-banner
        />

        <div class="position-relative z-index-10 bg-second py-5 py-lg-6 text-white">
            <div class="container">
                <div class="row">
                    <div
                        v-for="(section, index) in footer?.sections"
                        :key="`section_${ index }`"
                        class="col-lg-4 col-12 mb-4 mb-md-0"
                    >
                        <h6 class="text-uppercase tx-body text-white font-weight-bold mb-2">
                            {{ section.title }}
                        </h6>
                        <ul class="list-unstyled mb-0">
                            <li
                                v-for="(link, linkIndex) in section.links"
                                :key="`link_${ linkIndex }`"
                                class="pb-2 p-md-0"
                                :class="[link.class || '']"
                            >
                                <Link
                                    :href="link.url"
                                    class="text-white tx-small"
                                    :title="link.title"
                                >
                                    {{ link.title }}
                                    <span v-if="link.badge" class="badge badge-primary ml-1">{{ link.badge }}</span>
                                </Link>
                            </li>
                        </ul>
                        <hr class="my-5 bg-light-accent d-lg-none">
                    </div>
                </div>
                <hr class="my-5 bg-light-accent d-none d-lg-block">
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-6 mb-4 mb-lg-0 mt-2">
                        <ul class="list-inline tx-small mb-0">
                            <li
                                v-for="(socialLink, socialLinkIndex) in footer?.socialLinks"
                                :key="`socialLink_${ socialLinkIndex }`"
                                class="list-inline-item my-1"
                            >
                                <a
                                    :href="socialLink.url"
                                    target="_blank"
                                    :title="socialLink.title"
                                    class="text-white text-hover-primary icon-wrap-footer"
                                >
                                    <TheIcon
                                        :icon-id="socialLink.iconId"
                                        width="30"
                                        height="30"
                                    />
                                 
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-4 col-12 order-1 order-lg-0">
                        <ul class="pl-0 text-left text-lg-center text-white tx-small">
                            <li
                                v-for="(link, pageIndex) in footer?.pages"
                                :key="`page_${ pageIndex }`"
                                class="list-inline-item mr-0"
                                :class="{ 'with-dot-divider': pageIndex !== footer?.pages.length - 1 }"
                            >
                                <Link
                                    :href="link.url"
                                    :title="link.title"
                                    class="text-white"
                                >
                                    {{ link.title }}
                                </Link>
                            </li>
                        </ul>
                        <div class=" text-left text-lg-center tx-small">
                            Copyright © 2002 - {{ currentYear }} Hostelz.com
                        </div>
                    </div>
                    <div class="col-lg-4 col-6 mb-4 mb-lg-0">
                        <p class="currentCurrency" @click="showCurrencyAndScrollTop">
                            {{ currentCurrency }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<style lang="scss" scoped>
.with-dot-divider::after {
    content: '\B7';
    margin: 0 10px;
}

.currentCurrency {
    cursor: pointer;
    margin-bottom: 0;
    padding: 0 10px 10px;
    line-height: 22px;
    font-weight: 600;

    @media (max-width: 991px) {
        margin-top: 16px;
    }

    &:hover {
        text-decoration: underline;
    }
}

.icon-wrap-footer {
    transition: color .3s;
}
</style>