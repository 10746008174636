import { RoomType } from '@/Pages/the-home/enums/Search'
import dayjs from 'dayjs'
import { computed, ComputedRef, Ref, ref } from 'vue'
import { searchOptions } from './use-listing-search'
import { SearchOptions } from '@/Interfaces/SearchOptions'

export const dateRangeHeader: Ref<string[]> = ref([
    dayjs(searchOptions.value.startDate).format('YYYY-MM-DD'),
    dayjs(searchOptions.value.endDate).format('YYYY-MM-DD')
])
export const skipDateHeader = ref(searchOptions.value.skipDate)
export const roomTypeHeader: Ref<RoomType> = ref(searchOptions.value.roomType)
export const roomsQuantityHeader = ref(searchOptions.value.rooms)
export const guestsQuantityHeader = ref(searchOptions.value.people)
export const searchInputRefHeader: Ref<HTMLInputElement | null> = ref(null)
export const groupTypeHeader = ref(searchOptions.value.groupType)
export const groupAgeRangesHeader = ref(searchOptions.value.groupAgeRanges)
export const currencyHeader = ref(searchOptions.value.currency)

export const startDateHeader = computed(() => dayjs(dateRangeHeader.value[0]).format('YYYY-MM-DD'))
export const endDateHeader = computed(() => dayjs(dateRangeHeader.value[1]).format('YYYY-MM-DD'))

export const inputDateRangeHeader = computed(() => `${ dayjs(dateRangeHeader.value[0]).format('D MMM') } - ${ dayjs(dateRangeHeader.value[1]).format('D MMM') }`)

export const roomsHeader = computed(() => `${ roomsQuantityHeader.value } room${ roomsQuantityHeader.value > 1 ? 's' : '' }`)
export const guestsHeader = computed(() => `${ guestsQuantityHeader.value } guest${ guestsQuantityHeader.value > 1 ? 's' : '' }`)

export const isGroupTypesVisibleHeader = computed(() => guestsQuantityHeader.value > 7)

export const searchPayloadHeader: ComputedRef<SearchOptions> = computed((): SearchOptions => ({
    startDate: startDateHeader.value,
    endDate: endDateHeader.value,
    nights: dayjs(endDateHeader.value).diff(startDateHeader.value, 'day'),
    roomType: roomTypeHeader.value,
    people: guestsQuantityHeader.value,
    rooms: roomsQuantityHeader.value,
    groupType: groupTypeHeader.value,
    groupAgeRanges: groupAgeRangesHeader.value,
    currency: currencyHeader.value,
    skipDate: skipDateHeader.value
}))