<script setup lang="ts">

import TheIcon from '@/Components/TheIcon.vue'
import { onMounted } from 'vue'
import { usePage, Link } from '@inertiajs/vue3'
import { comparisonCount,setComparisons } from '@/Composables/use-comparison'

const page = usePage()

onMounted(() => {
    setComparisons(page.props?.comparison ?? [])
})

</script>

<template>
    <Link
        :href="route('comparison')"
        class="card-fav-icon position-relative z-index-40 opacity-9 bg-light comparison"
        title="Start Comparing Hostels Now!"
    >
        <TheIcon
            class="cursor-pointer"
            icon-id="comparison-tool-2"
            width="22"
            height="24"
        />

        <span class="comparison-count ml-1">{{ comparisonCount }}</span>
    </Link>
</template>

<style scoped lang="scss">
.comparison-count {
    position: absolute;
    top: -7px;
    right: -7px;
    background: #4A5268;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 15px;
    font-size: 13px;
}

.card-fav-icon.comparison {
    line-height: 2.25rem;
    transition: background-color 0.3s ;
   
    &:hover {
        opacity: 0.9;
    }
}

.disabled-class {
    pointer-events: none;
}
</style>